import React, { useEffect } from 'react';
import { bool, string } from 'prop-types';
import { Helmet } from 'react-helmet-async';

const SmsTag = ({ dataToken, tagHeadline, placeholder }) => {
  useEffect(() => {
    const scriptTag = document.createElement('script');

    scriptTag.src = 'https://assets.jointacademy.com/framework/sms/v4.0.0/sms.js?v=aosiii2dfh';
    scriptTag.async = false;
    scriptTag.defer = true;

    document.body.appendChild(scriptTag);
    return () => {
      document.body.removeChild(scriptTag);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://assets.jointacademy.com/framework/sms/v4.0.0/sms.css"
        />
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/intlTelInput.min.js"
          data-token={dataToken}
        />
      </Helmet>

      <p className="margin-top--sm margin-bottom--xs">{tagHeadline}</p>
      <div className="jaSmsFormWrapper" data-placeholder={placeholder} />
    </div>
  );
};

SmsTag.defaultProps = {
  tagHeadline: '',
  dataToken: '',
  placeholder: '',
};

SmsTag.propTypes = {
  dataToken: string,
  tagHeadline: string,
  placeholder: string,
};

export default SmsTag;
