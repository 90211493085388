import React from "react"
// import styled from "styled-components"
// import "./socialMinimal.scss"

// import { respondTo } from "./_respondTo"
import Stars from "../.../../../assets/stars.svg"

// export const SocialMinimalContainer = styled.div`
//   /* min-width: 600px; */
//   align-items: center;
//   justify-content: center;
//   div {
//     align-items: center;
//     justify-content: center;

//     ${respondTo.md`
//     justify-content: start;
//   `}
//   }
//   .childTwo {
//     margin-top: 12px;
//     ${respondTo.md`
//     margin-top:unset;

//   `}
//   }
// `

const SocialMinimal = () => {
  // const Data = styled.p`
  //   font-size: 22px !important;
  // `
  // const Icon = styled.img`
  //   width: 108px;
  //   display: flex;
  //   align-items: center;
  // `
  return (
    <div className="flex flex--wrap margin-bottom--md SocialMinimal">
      <div className="flex flex--center-y col col--12 col--md-8">
        <p className="text--title text--bold text--md opacity--6 margin-right--xxs Data">
          4.8
        </p>
        <img className="icon--md Icon" alt="Stars" src={Stars} />
        <p className="text--sm text--title opacity--6 margin-left--xxs">
          I betyg på Google
        </p>
      </div>

      <div className="flex flex--center-y col col--12 col--md-4 childTwo">
        <p className="text--title text--bold text--md opacity--6 margin-right--xxs Data">
          99%
        </p>
        <p className="text--sm text--title opacity--6">Patientnöjdhet</p>
      </div>
    </div>
  )
}

export default SocialMinimal
