import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { HERO_IMAGE_ILLUSTRATION_HOLDING_PHONE_APP, HERO_IMAGE_ILLUSTRATION_WOMAN_FLOOR } from '@/src/common/consts/hero';
import illustrationCircleApp from '@assets/illustration_circle_app.svg';
import SmsTag from '../../smsTag';
import SocialMinimal from '../../atoms/socialMinimal';
import Button from '../../Button';

const heroAnimationCenter = ({
  dataTheme,
  title,
  titleClassName,
  text,
  textClassName,
  dataToken,
  tagHeadline,
  imageAlt = 'Hero',
  containerSize = 'sm',
  showSocial = false,
  imageVariant = HERO_IMAGE_ILLUSTRATION_WOMAN_FLOOR,
  showSmsMobile = false,
  elementId = 'herotext',
}) => {
  const imageTop = imageVariant === HERO_IMAGE_ILLUSTRATION_HOLDING_PHONE_APP;

  return (
    <section
      className="heroAnimationCenter"
      data-theme={dataTheme}
      id="top"
      style={{ overflow: 'visible' }}
    >
      <div className={`container container--${containerSize}`}>
        <div id={elementId} className="margin-top--sm flex flex--center-y text--center align--center alignItems">
          {imageVariant === HERO_IMAGE_ILLUSTRATION_HOLDING_PHONE_APP && (
            <img
              className="hero-image-svg"
              alt="Joint Academy"
              src={illustrationCircleApp}
            />
          )}

          <h1 className={`${imageTop ? 'hero-image-top margin-bottom--sm' : 'margin-bottom--md'} ${titleClassName}`}>
            {title}
          </h1>

          <p className={textClassName}>{text}</p>

          <div className={`${showSmsMobile ? '' : 'display--md'} margin-top--xs hero-sms-form`}>
            <SmsTag dataToken={dataToken} tagHeadline={tagHeadline} />
          </div>

          {!showSmsMobile && (
            <div className="hide--md margin-top--xs">
              <Button
                dataGoal="Onboarding Viewed"
                href="https://app.adjust.com/qb80ge1"
                classname="margin-top--md btn btn--fullwidth btn--lg btn--primary"
                text="Kom igång idag"
              />
            </div>
          )}

          {showSocial && (
            <div className="margin-top--sm">
              <SocialMinimal />
            </div>
          )}

          {imageVariant === HERO_IMAGE_ILLUSTRATION_WOMAN_FLOOR && (
            <StaticImage
              src="../../../images/img-hero-desktop@3x.png"
              alt={imageAlt}
              placeholder="tracedSVG"
              width={400}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default heroAnimationCenter;
