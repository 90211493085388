import React from 'react';
import { Helmet } from 'react-helmet-async';
import contentData from './data.json';

const SocialProofFeedST = ({ lang, sectionClassName, hideText }) => {
  const content = contentData[lang || 'se_sv'];

  return (
    <section
      data-theme="white"
      className={`SocialProofFeedST ${sectionClassName}`}
    >
      <div className="container container--lg margin-top--lg">
        {content && (
          <div className="col col--12 col--md-12">
            {!hideText && (
              <div>
                <h2 className="margin-bottom--sm text--center">{content.title}</h2>
                {!!content.text && (
                  <div className="text--component text--center margin-bottom--xs">
                    <p>{content.text}</p>
                  </div>
                )}
              </div>
            )}

            <div className={`${content.elfsight}`} />
          </div>
        )}
      </div>

      <Helmet>
        <script src="https://apps.elfsight.com/p/platform.js" defer />
      </Helmet>
    </section>
  );
};

export default SocialProofFeedST;
