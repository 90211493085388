import React from "react"

import contentData from "../../../components/staticSections/GetStartedST/data.json"
import Image from "../../image"

const GetStartedST = ({
  lang,
  ctaButtonText,
  ctaDataGoal,
  ctaHref,
  renderDownloadButton,
  storybook,
  extraContent,
}) => {
  const language = typeof lang === "undefined" ? "se_sv" : lang
  const content = contentData[language]

  return (
    <section data-theme="white" className="GetStartedST">
      <div className="container container--lg margin-top--lg margin-bottom--lg margin---none">
        {content &&
          content.map(main => (
            <div key={main.title}>
              <div className="text--component text--center margin-bottom--md">
                <h2 className="margin-bottom--xs">{main.title}</h2>
                <p>{main.text}</p>
              </div>
              <div className="grid grid--gap-md text--center">
                {main.cards &&
                  main.cards.map((card, index) => (
                    <div key={card.icon} className="col col--12 col--sm-3">
                      {storybook ? (
                        <img
                          src="https://via.placeholder.com/100x100"
                          alt="sb pic"
                        />
                      ) : (
                        <Image
                          alt={card.alt}
                          filename={card.icon}
                          classname="widthIcons"
                        />
                      )}
                      <div className="text--component margin-top--sm">
                        {!!card.title && <h3 className="text--md">{card.title}</h3>}
                        <p>
                          {!card.title && (
                            <strong className="text--bold">{`${index + 1}. `}</strong>
                          )}
                          {card.text}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        {extraContent}
        {renderDownloadButton && (
          <div className="text--center">
            <a
              data-goal={ctaDataGoal}
              href={ctaHref}
              className="btn btn--primary btn--md btn--lgMob margin-top--md"
            >
              {ctaButtonText}
            </a>
          </div>
        )}
      </div>
    </section>
  )
}
export default GetStartedST
